<template>
  <div class="x-content">
    <van-image width="100px" height="100px" :src="iamges.logo" class="x-logo" />

    <van-form @submit="onSubmit" class="x-form">
      <div class="x-form-item">
        <div class="x-table-iocn">
          <van-image width="36px" :src="iamges.phone" />
        </div>
        <input
          type="text"
          v-model="fromData.phone"
          class="x-inpurt x-inpurt-phone"
          placeholder="输入您的手机号"
        />
      </div>

      <div class="x-form-item">
        <div class="x-table-iocn">
          <van-image width="36px" :src="iamges.password" />
        </div>
        <input
          v-model="fromData.code"
          class="x-inpurt x-inpurt-password"
          placeholder="输入验证码"
        />
        <van-button
          type="primary"
          native-type="button"
          :class="is_code ? 'x-noCode' : 'x-code'"
          @click="getCode"
          >{{ is_code ? code_item + "s" : "获取验证码" }}
        </van-button>
      </div>

      <van-button
        round
        block
        native-type="submit"
        :class="fromData.phone && fromData.code ? 'x-submit' : 'x-noSubmit'"
        >登录</van-button
      >
    </van-form>
  </div>
</template>
<script>
import rules from "../util/rules";
import logo from "../assets/logo.png";
import password from "../assets/icon_password@2x.png";
import phone from "../assets/icon_phone@2x.png";
import { getSession, setSession } from "../util/sessionStorage";

export default {
  data() {
    return {
      is_submit: false,
      is_code: false,
      code_item: 0,
      iamges: {
        logo,
        password,
        phone
      },

      fromData: {
        phone: "", //手机号
        code: "" //验证码
      }
    };
  },
  methods: {
    //提交表单
    onSubmit(e) {
      if (!this.fromData.phone || !this.fromData.code) {
        return;
      }
      if (!rules.phoneLength.pattern.test(this.fromData.phone)) {
        this.$Dialog({ message: rules.phoneLength.message });
        return;
      }
      if (!rules.phoneFormat.pattern.test(this.fromData.phone)) {
        this.$Dialog({ message: rules.phoneFormat.message });
        return;
      }
      if (this.fromData.code.length <= 0) {
        this.$Dialog({ message: "请输入验证码" });
        return;
      }

      this.$api.postLogin(this.fromData).then((res) => {
        console.log("登陆==>>", res);
        if (res.code != 1000) {
          this.$Dialog.alert({ message: res.message });
          return;
        }

        let istoken = setSession("token", res.data.token);
        if (istoken) {
          this.$router.push({ path: "/membershipCenter" });
        }
      });
    },
    // 获取验证码
    getCode() {
      if (this.is_code) {
        return;
      }
      if (!rules.phoneLength.pattern.test(this.fromData.phone)) {
        this.$Dialog({ message: rules.phoneLength.message });
        return;
      }
      if (!rules.phoneFormat.pattern.test(this.fromData.phone)) {
        this.$Dialog({ message: rules.phoneFormat.message });
        return;
      }

      this.$api.getPhoneCode(this.fromData.phone).then((res) => {
        if (res.code != 1000) {
          this.$Dialog.alert({ message: res.message });
          return;
        }
        this.code_item = 60;
        this.is_code = true;
        this.countDown();
      });
    },

    // 倒计时
    countDown() {
      var intitem = setInterval(() => {
        this.code_item--;
        if (this.code_item <= 0) {
          clearInterval(intitem);
          this.is_code = false;
        }
      }, 1000);
    }
  },
  mounted() {
    document.title = "会员登录";
  }
};
</script>
<style lang="less" scoped>
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}

.x-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  box-sizing: border-box;
  background-color: white;
  height: 100vh;

  .x-submit {
    height: 44px;
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 30px;
  }

  .x-noSubmit {
    height: 44px;
    background: #d8d8d8;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 30px;
  }
}

.x-form {
  width: 100%;
  padding: 0px 32px;
  padding-top: 50px;
  box-sizing: border-box;

  .x-form-item {
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .x-table-iocn {
      flex: 4;
    }

    .x-inpurt {
      width: 0px;
      padding: 8px 12px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      font-size: 14px;
      font-weight: 400;
    }

    .x-inpurt-phone {
      flex: 18;
    }

    .x-inpurt-password {
      flex: 10;
    }

    .x-inpurt:focus {
      border: 1px solid #ec6941;
    }

    .x-code {
      flex: 5;
      height: 36px;
      background: #fa8925;
      border-radius: 4px;
      border: none;
      margin-left: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }

    .x-noCode {
      flex: 5;
      height: 36px;
      background: #d8d8d8;
      border-radius: 4px;
      border: none;
      margin-left: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
